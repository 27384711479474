import { base64toBytes, bytesToBase64, bytesToHex } from "../lib/utils";
import DigitalAgentService from "../services/DigitalAgentService";
import SS, { StoredTypePrefix, pkToStoredType } from "../services/StorageService";
import VaultManager from "./VaultManager";
import Vault from "../models/Vault";
import { Pk } from "../models/types";

type BackupEventType = 'backup' | 'delete';

type BackupEventDict = {
    event_type: BackupEventType,
    client_pk: string,
    created_at: number,
}

type BackupState = {
    queue: {[pk: string]: BackupEventType},
    vaultPk: string,
}

const RESTORE_BATCH_SIZE = 5;
const KINDS_TO_BACKUP = ['contact', 'secret', 'recoverSplit', 'guardian'];

function getPksAndUpdated(vaultManager: VaultManager): [string, number][] {
    const pkAndUpdated = []
    for(const kind of KINDS_TO_BACKUP) {
        const objects = vaultManager.managerByKind(kind).getAllArray();
        pkAndUpdated.push(...objects.map(object => [object.pk, object.updated]));
    }
    return pkAndUpdated;
}

class BackupUtil {
    _vault: Vault;
    _manager: VaultManager;

    state: BackupState;

    constructor(vault: Vault, manager: VaultManager) {
        this._vault = vault;
        this._manager = manager;
    }
    get pk(): string {
        return StoredTypePrefix.backupState + this._vault.b58_verify_key;
    }
    uploadObject(object: any, eventType: BackupEventType) {
        const objectBytes = Buffer.from(JSON.stringify(object), 'utf-8');
        const encryptedBytes = this._vault.encryptPayload(objectBytes)
        const base64Encrypted = bytesToBase64(encryptedBytes);
        // console.log('AAA Bytes', bytesToHex(encryptedBytes.slice(0,16)))
        // console.log('AAA B64', base64Encrypted.slice(0,16))
        return DigitalAgentService.uploadObject(
            this._vault,
            base64Encrypted,
            eventType,
            object.pk
        )
    }
    fetchObjects(pks: string[]): Promise<any|false> {
        return DigitalAgentService.fetchObjects(this._vault, pks);
    }
    async getEvents(opts: {
            after?: number, before?: number,
            pk?: Pk, last_only?: boolean}): Promise<BackupEventDict[]> {
        return await DigitalAgentService.getBackupEvents(this._vault, {
            after: 0,
            before: Math.floor(Date.now() / 1000) + 1,
            last_only: true,
            ...opts, // if passed will overwrite after and before
        });
    }
    async loadState(): Promise<void> {
        const state = await SS.get(this.pk)
        if(state) {
            this.state = state;
        } else {
            this.state = {
                queue: {},
                vaultPk: this._vault.pk,
            }
            await this.saveState();
        }
    }
    async saveState() {
        return SS.save(this.pk, this.state);
    }
    async backup() {
        // if queue is empty, return
        // upload event to digital agent
        // save and call again
        const keys = Object.keys(this.state.queue);
        if (keys.length === 0) return;
        keys.forEach(async (pk) => {
            const eventType = this.state.queue[pk];
            if(eventType === 'delete') {
                await DigitalAgentService.uploadObject(
                    this._vault,
                    '',
                    'delete',
                    pk
                );
            } else {
                const object = await this._manager.get(pk);
                await this.uploadObject(object.toDict(), eventType);
            }
            delete this.state.queue[pk];
            await this.saveState();
        })
    }
    async addToQueue(pk: string, eventType: BackupEventType = 'backup') {
        console.log('[BackupUtil.addToQueue]', pkToStoredType(pk), pk, eventType);
        this.state.queue[pk] = eventType;
        this.saveState();
        this.backup();
        return;
    }
    async addManyToQueue(pks: string[], eventType: BackupEventType = 'backup') {
        console.log('[BackupUtil.addManyToQueue]', pks, eventType)
        for(const pk of pks)
            this.state.queue[pk] = eventType;
        this.saveState();
        this.backup();
        return;
    }
    async getMissingFromlocal() {
        // find missing local (i.e. on remote but not on local)
        const backupEvents = await this.getEvents({after: 0, last_only: true});
        const localPkAndUpdated = getPksAndUpdated(this._manager)
        const missing = []
        for(const event of backupEvents) {
            const [pk, updated] = localPkAndUpdated.find(([pk, updated]) => pk === event.client_pk) || [];
            if(!pk) { //  add later if remote newer (for sync...) || updated < event.created_at
                missing.push(event.client_pk);
            }
        }
        return missing;
    }
    async restore(force=false): Promise<{[pk: string]: boolean}> {
        let pksToRestore = []
        if(force) {
            const backupEvents = await this.getEvents({after: 0, last_only: true});
            pksToRestore = backupEvents
                .filter(e => e.event_type == 'backup') // no need for delete events
                .map(e => e.client_pk);
        } else {
            pksToRestore = await this.getMissingFromlocal();
        }
        if(pksToRestore.length === 0) {
            console.log('[BackupUtil.restore] Nothing to restore')
            return {};
        }
        console.log('[BackupUtil.restore] restoring missing:', pksToRestore)
        const out = {}
        // do RESTORE_BATCH_SIZE at a time
        for(let i = 0; i < pksToRestore.length; i += RESTORE_BATCH_SIZE) {
            const cur_pks = pksToRestore.slice(i, i+RESTORE_BATCH_SIZE);
            console.log('Restoring PKs: ', cur_pks)
            const objects = await this.fetchObjects(cur_pks);
            const savePromises = [];
            for(const object of objects) {
                try {
                    const data_bytes = base64toBytes(object);
                    const decrypted = this._vault.decryptPayload(data_bytes);
                    if(decrypted) {
                        const data = JSON.parse(new TextDecoder().decode(decrypted));
                        if(cur_pks.indexOf(data.pk) !== -1) {
                            cur_pks.splice(cur_pks.indexOf(data.pk), 1);
                            savePromises.push(SS.save(data.pk, data));
                            out[data.pk] = true;
                        }
                    }
                } catch(e) {
                    console.log('[BackupUtil.restore] Error:', e)
                    console.log(object)
                }
            }
            for(const pk of cur_pks) {
                out[pk] = false;
            }
            await Promise.all(savePromises);
            // TODO: after restore need to have managers load into their _objects
            // e.g. this._manager.contactsManager.load()
        }
        return out;
    }
    async getMissingOnRemote() {
        // checks what is backedup vs. what we have
        const backupEvents = await this.getEvents({after: 0, last_only: true});
        const localPkAndUpdated = getPksAndUpdated(this._manager)
        const missing = []
        for(const [pk, updated] of localPkAndUpdated) {
            const event = backupEvents.find(e => e.client_pk === pk);
            if(!event || event.created_at < updated) {
                missing.push(pk);
            }
        }
        return missing;
    }
    async backupMissing() {
        const missing = await this.getMissingOnRemote();
        if(missing.length) {
            console.log('[BackupUtil.backupMissing] adding queue', missing)
            await this.addManyToQueue(missing);
        } else {
            console.log('[BackupUtil.backupMissing] nothing missing')
        }
        return
    }
}

export default BackupUtil;