export const test_vaults = [
    {
        "pk": "v__9gFY2aQrB5VK1YnxUHSn64Ppmuno8XZXgABfgTB5zgbS",
        "uuid": "654e4196-3d74-4b89-86b0-c5f45f426701",
        "name": "alice",
        "email": "alice@arxsky.com",
        "display_name": "TV—Alice",
        "digital_agent_host": "",
        "words": "alley maze evoke auto pull skull run credit margin ignore dune brisk",
        "signing_key": "4MW9HfNeyEDggPhPaWHtSzn5FaX8jSF1wvvVUi8AxRbYSuvBNxTZA5oVDJ8tmUKA4dtxJ2FDkQaVSQVTqFFBJpxQ",
        "verify_key": "9gFY2aQrB5VK1YnxUHSn64Ppmuno8XZXgABfgTB5zgbS",
        "private_key": "BXZFfBGhytYSoQbuS14Q7iAoiGE4JhHA5jA61k6y4SwH",
        "public_key": "BVVHk6i8MNDjo78YM7uUFedtGPYgeXnXiNNxmFkP9i6T",
        "registered": false,
        "short_code": "",
        "recovery": false,
    },
    {
        "pk": "v__EtCnZqvnQ4qNq1wV5yjK2hMTrg1i3iPFESrD6w7mGP3E",
        "uuid": "5953d028-3acf-461d-aa38-54aa6199624d",
        "name": "bob",
        "email": "bob@arxsky.com",
        "display_name": "TV—Bob",
        "digital_agent_host": "",
        "words": "tissue noodle grant poem asthma print mercy prize hunt angle alert bridge",
        "signing_key": "4U74prLHNzmHsJoqjeCtZxE6PnPQ4BFNhtUNL7UfGXR8fCsMYBAtQThhZ3WbnPvtyrfZh7NixjtEhoexdTRV1ByS",
        "verify_key": "EtCnZqvnQ4qNq1wV5yjK2hMTrg1i3iPFESrD6w7mGP3E",
        "private_key": "HmyXGfLEfvnDxfjaHWF4C7SB37yZ5Bady57SEgbkG6U1",
        "public_key": "D2AgAghipD9jgZcg7DZgZNAy2EfE9a8MjeXkZAaezW6S",
        "registered": false,
        "short_code": "",
        "recovery": false,
    },
    {
        "pk": "v__D5EBA8cZDo7kQGCqepKGGkkWGy9b1c5oXnQE5N9T1xnK",
        "uuid": "afbd4cba-6e30-4857-b477-88255e5bcb1e",
        "name": "charlie",
        "email": "charlie@arxsky.com",
        "display_name": "TV—Charlie",
        "digital_agent_host": "",
        "words": "top cancel breeze nature place deny wink first domain object legend matter",
        "signing_key": "5wVUYFv2eGixHk7DVRKmN689gijFynY94a1jsAAgrXCAt4725iL5fZYCDnCPELX8J7f6nHBk4kGX3KomAykBX76w",
        "verify_key": "D5EBA8cZDo7kQGCqepKGGkkWGy9b1c5oXnQE5N9T1xnK",
        "private_key": "4J9ojp1TvJe1B7yRGbuBtoW1LSMy3xZgu3sRGsCNTvi7",
        "public_key": "DXkGB7X2UbeS4Kk7uvXAEkdQ6bNAa3jBpo9GgwuYkXEm",
        "registered": false,
        "short_code": "",
        "recovery": false,
    },
    {
        "pk": "v__9hBRoYiHKeGj1y49BSutEuFANaafBG3s1aVnrX5bwYFq",
        "uuid": "4c7f6873-6284-4b32-bc26-068157805919",
        "name": "dan",
        "email": "dan@arxsky.com",
        "display_name": "TV—Dan",
        "digital_agent_host": "",
        "words": "depth sweet minimum remember orient electric virtual maze blade dream hunt find",
        "signing_key": "57AkBeA22ypf8cR4GmDBKbgofexDba7pWc6iGkEGqCq4zYRTPXCVDKmnbCC9RyT59MhBmsjjk6aUxQJLgouWBDvw",
        "verify_key": "9hBRoYiHKeGj1y49BSutEuFANaafBG3s1aVnrX5bwYFq",
        "private_key": "AiPiDob52BPyAwLm76JbyCabDKuVCCcQceQuwpFbXnpf",
        "public_key": "2pVqFxzcckQXp8CxwzLc6KZySxAJURUApLeTRQufcXDf",
        "registered": false,
        "short_code": "",
        "recovery": false,
    }
]