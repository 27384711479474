export const testNotifications = [
    {
        pk: '1',
        type: 'contact.request',
        data: {
            title: 'Contact Invite',
            short_text: 'You have been invited to connect',
            detailed_text: 'You have been invited to connect with a new contact',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '2',
        type: 'contact.accept',
        data: {
            title: 'Contact Accept',
            short_text: 'Your contact request has been accepted',
            detailed_text: 'Your contact request has been accepted',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '3',
        type: 'app.test',
        data: {
            title: 'Test Notification',
            short_text: 'This is a test notification',
            detailed_text: 'This is a test notification',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '4',
        type: 'app.info',
        data: {
            title: 'Info Notification',
            short_text: 'This is an info notification',
            detailed_text: 'This is an info notification',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '5',
        type: 'app.alert',
        data: {
            title: 'Alert Notification',
            short_text: 'This is an alert notification',
            detailed_text: 'This is an alert notification',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '6',
        type: 'app.warning',
        data: {
            title: 'Warning Notification',
            short_text: 'This is a warning notification',
            detailed_text: 'This is a warning notification',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '7',
        type: 'recoverSplit.invite',
        data: {
            title: 'Recover Split Invite',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '8',
        type: 'recoverSplit.accept',
        data: {
            title: 'Recover Split Response',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '20',
        type: 'recoverSplit.decline',
        data: {
            title: 'Recover Split Response',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '9',
        type: 'recoverCombine.manifest',
        data: {
            title: 'Recover Combine Manifest',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '10',
        type: 'recoverCombine.request',
        data: {
            title: 'Recover Combine Request',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '11',
        type: 'recoverCombine.accept',
        data: {
            title: 'Recover Combine Response',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
    {
        pk: '12',
        type: 'recoverCombine.decline',
        data: {
            title: 'Recover Combine Response',
            short_text: 'You have been invited to recover a secret',
            detailed_text: 'You have been invited to recover a secret',
        },
        created: 1,
        vaultPk: '1',
    },
]
