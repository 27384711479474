import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, Pressable} from 'react-native';

import MainContainer from '../../components/MainContainer';
import ds from '../../assets/styles';
import tw from '../../lib/tailwind';

import DAS from '../../services/DigitalAgentService';
import { useSessionContext } from '../../contexts/SessionContext';
import VaultManager from '../../managers/VaultManager';
import Vault from '../../models/Vault';
import ContactsManager from '../../managers/ContactsManager';
import BackupUtil from '../../managers/BackupUtil';
import { base64toBytes, bytesToBase64, encryptionKey,
    } from '../../lib/utils';


const testEncryptDecrypt = async (vault: Vault) => {
    const key = await encryptionKey()
    const testObject = {'hello': 'world'}
    const testString = JSON.stringify(testObject)
    const testBytes = Buffer.from(testString, 'utf-8')
    const encryptedBytes = vault.encryptPayload(testBytes)
    console.log(encryptedBytes.slice(0,16))
    const base64Encrypted = bytesToBase64(encryptedBytes)
    console.log(base64Encrypted)
    const bytesToDecrypt = base64toBytes(base64Encrypted)
    console.log(bytesToDecrypt.slice(0,16))
    const decryptedBytes = vault.decryptPayload(bytesToDecrypt)
    console.log(decryptedBytes)
    // console.log(new TextDecoder().decode(decryptedBytes))
    const toTest = JSON.parse(new TextDecoder().decode(decryptedBytes));
    console.log(toTest)
}

const uploadContacts = (vault: Vault, manager: VaultManager, backupUtil: BackupUtil) => {
    console.log('uploadContacts');
    const contactsManager: ContactsManager = manager.contactsManager;
    const objects = contactsManager.getContactsArray().map((contact) => {
        return {
            pk: contact.pk,
            data: contact.name,
        }
    })
    console.log(objects)
    objects.forEach((object) => {
        backupUtil.uploadObject(object, 'backup')
    })
}

const uploadSingle = async (backupUtil: BackupUtil, contactsManager: ContactsManager) => {
    console.log('uploadSingle');
    const object = contactsManager.getContactsArray()[0].toDict()
    console.log(object)
    backupUtil.uploadObject(object, 'backup')
}
const getBackupEvents = async (backupUtil: BackupUtil) => {
    console.log('getBackupEvents');
    const events = await DAS.getBackupEvents(
        backupUtil._vault, 
        {after: 0, before: Math.floor(Date.now() / 1000)})
    console.log(events)
    return events
}
const fetchObjects = async (backupUtil: BackupUtil, backupEvents: any[]) => {
    console.log('fetchObjects');
    const pks = backupEvents.map((event) => event.client_pk)
    console.log(pks)
    // return
    const objects = await DAS.fetchObjects(backupUtil._vault, pks)
    console.log(objects)
}
const doRestore = async (backupUtil: BackupUtil) => {
    backupUtil.restore()
}
const backupMissing = async (backupUtil: BackupUtil) => {
    backupUtil.backupMissing()
}


const DevBackupScreen = () => {
    const { vault, manager } = useSessionContext();
    const [ backupEvents, setBackupEvents ] = useState([])
    const backupUtil = manager.backupUtil

    const doGetBackUpEvents = async () => {
        const result = await getBackupEvents(backupUtil)
        setBackupEvents(result)
    }

    return (
        <MainContainer buttonRow={null} header='Dev Backup'>
            <Pressable onPress={() => uploadContacts(vault, manager, backupUtil)} style={[ds.button, ds.greenButton]}>
                <Text style={ds.buttonText}>Upload Contacts</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={() => fetchObjects(backupUtil, ['c__bob', 'c__dan', 'c__charlie'])} style={[ds.button, ds.blueButton]}>
                <Text style={ds.buttonText}>Get Contacts</Text>
            </Pressable>
            <View>
                <Text style={ds.text2xl}>Backup Manager</Text>
            </View>
            <View style={tw`h-4`} />
            <Pressable onPress={() => uploadSingle(backupUtil, manager.contactsManager)} style={[ds.button, ds.blueButton, tw`w-full`]}>
                <Text style={ds.buttonText}>Upload Single</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={doGetBackUpEvents} style={[ds.button, ds.blueButton, tw`w-full`]}>
                <Text style={ds.buttonText}>Get Backup Events</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={() => fetchObjects(backupUtil, backupEvents)} style={[ds.button, ds.blueButton, tw`w-full`]}>
                <Text style={ds.buttonText}>Get Backup Objects</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={() => doRestore(backupUtil)} style={[ds.button, ds.redButton, tw`w-full`]}>
                <Text style={ds.buttonText}>Restore Objects</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={() => backupMissing(backupUtil)} style={[ds.button, ds.redButton, tw`w-full`]}>
                <Text style={ds.buttonText}>Backup Missing</Text>
            </Pressable>
            <View style={tw`h-4`} />
            <Pressable onPress={() => testEncryptDecrypt(backupUtil._vault)} style={[ds.button, ds.redButton, tw`w-full`]}>
                <Text style={ds.buttonText}>TEST ENCRYPT DECRYPT OBJECT</Text>
            </Pressable>
            <View style={tw`h-4`} />
        </MainContainer>
    );
};

export default DevBackupScreen;